function guest(to, from, next) {
	const token = localStorage.getItem('@stup:token')
	if (token) {
		next(process.env.VUE_APP_INITIAL_ROUTE || 'dashboard')
	}

	if (to && to.path == '/register' && process.env.VUE_APP_ENABLE_REGISTER == 'false') {
		next('error')
	}

	return next()
}

export default guest
