import store from '../store'

function auth(to, from, next) {
	const token = localStorage.getItem('@stup:token')
	if (!token) {
		next('login')
	}

	if (to && to.path == '/dashboard' && process.env.VUE_APP_ENABLE_DASHBOARD == 'false') {
		next('error')
	}

	if (store.state.user.permissions) {
		const permission = store.state.user.permissions.find(e => e.slug === to.name.toLowerCase())

		if (permission && !permission.role.read) {
			next('error')
		}
	}

	return next()
}

export default auth
